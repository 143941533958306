import { ActionTree } from 'vuex';
import { SearchState } from './types';
import { RootState } from '../types';
import { ProblemDefinition } from '@/domain/Problem';
import { ProblemSetDefinition, ProblemSetType } from '@/domain/ProblemSet';
import {
  searchForProblems,
  searchForProblemSets,
  ProblemsFilterParams,
  ProblemSetsFilterParams,
} from '@/api/core/content.api';
import {
  DefinitionParams,
  ObjectList,
  PagingParams,
} from '@/api/core/base.api';

export const actions: ActionTree<SearchState, RootState> = {
  requestSkillBuildersBySkill(
    context,
    payload: {
      filterParams: ProblemSetsFilterParams;
      pagingParams: PagingParams;
      definitionParams: DefinitionParams;
      abortController?: AbortController;
    }
  ): Promise<ObjectList<ProblemSetDefinition>> {
    const { filterParams, pagingParams, definitionParams, abortController } =
      payload;
    return searchForProblemSets(
      filterParams,
      pagingParams,
      definitionParams,
      abortController
    ).then((result) => {
      // NOT downloading the Problem Set tree because we do not show its content in the
      // search page so not needed.
      const psCeris: string[] = [];
      for (const skillBuilder of result.data) {
        context.commit('content/setProblemSet', skillBuilder, { root: true });
        psCeris.push(skillBuilder.xref);
      }
      context.commit('setSkillBuilders', psCeris);
      context.commit('setSkillBuilderNextPageToken', result.nextPageToken);
      return result;
    });
  },
  async searchProblemsBySkill(
    context,
    payload: {
      filterParams: ProblemsFilterParams;
      pagingParams: PagingParams;
      definitionParams: DefinitionParams;
      abortController?: AbortController;
    }
  ): Promise<void> {
    context.commit('setIsDownloadingProblems', true);

    const { filterParams, pagingParams, definitionParams, abortController } =
      payload;

    // If this is the first search, then reset before requesting problems
    if (!pagingParams.nextPageToken) {
      context.commit('resetProblemSearch');
    }

    const searchResults: string[] = [...context.state.searchResults];

    const promises = [];

    promises.push(
      searchForProblems(
        filterParams,
        pagingParams,
        definitionParams,
        abortController
      )
        .then((result) => {
          const prCeris = [];

          for (const problem of result.data) {
            context.commit('content/setProblem', problem, { root: true });
            prCeris.push(problem.xref);
          }

          searchResults.push(...prCeris);

          context.commit('setNextPageToken', result.nextPageToken);

          if (result.count !== undefined) {
            context.commit('setProblemCount', result.count);
          }
        })
        .catch(() => {
          // If any errors are thrown, disable further redownloading
          context.commit('setShouldDownloadMoreProblems', false);
        })
    );

    const psPagingParams = {
      limit: pagingParams.limit,
      nextPageToken: context.state.problemSetsNextPageToken,
    };

    promises.push(
      searchForProblemSets(
        {
          skills: filterParams.skills,
          isResearch: false,
          isCertified: true,
          types: [ProblemSetType.MULTI_PART_PROBLEM_SET],
          problemTypes: filterParams.types,
        },
        psPagingParams,
        definitionParams,
        abortController
      )
        .then((result) => {
          const promises = [];

          for (const multiPart of result.data) {
            // Commit downloaded Problem Set to store to avoid downloading again.
            context.commit('content/setProblemSet', multiPart, { root: true });

            promises.push(
              context
                .dispatch(
                  'content/getProblemSetTree',
                  {
                    xref: multiPart.xref,
                  },
                  { root: true }
                )
                .then((ps) => {
                  // Store this in the search store. This Problem Set should contain
                  // ALL children downloaded.
                  searchResults.push(ps.xref);
                })
            );
          }

          context.commit('setProblemSetsNextPageToken', result.nextPageToken);

          if (result.count !== undefined) {
            context.commit('setProblemSetCount', result.count);
          }

          return Promise.all(promises);
        })
        .catch(() => {
          // If any errors are thrown, disable further redownloading
          context.commit('setShouldDownloadMoreProblems', false);
        })
    );

    return Promise.all(promises).then(() => {
      context.commit('setSearchResults', searchResults);
      context.commit('setIsDownloadingProblems', false);
    });
  },
};
