var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-cy":"problem-set-builder-menu"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"text-body-2"},[(
          _vm.parentProblemSet?.problemSetType ==
          _vm.ProblemSetType.MULTI_PART_PROBLEM_SET
        )?[(_vm.parentProblemSet.xref.startsWith('W'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Copy multi-part Problem ")]),_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Replace multi-part Problem with Copy ")])]}}],null,false,325091069)},[_c('span',[_vm._v("Problem must be published to copy")])]):[(
              _vm.grandparentProblemSet?.permissions.includes(
                _vm.AclPermissionType.UPDATE
              )
            )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('open-copy-pr-dialog', {
                xref: _vm.parent,
                parent: _vm.grandparent,
              })}}},[_vm._v(" Copy multi-part Problem ")]):_vm._e(),(
              _vm.grandparentProblemSet?.permissions.includes(
                _vm.AclPermissionType.UPDATE
              )
            )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('open-copy-pr-dialog', {
                xref: _vm.parent,
                parent: _vm.grandparent,
                replace: true,
              })}}},[_vm._v(" Replace multi-part Problem with Copy ")]):_vm._e()],(
            _vm.grandparentProblemSet?.permissions.includes(
              _vm.AclPermissionType.UPDATE
            )
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openRemoveDialog(_vm.RemoveDialogMode.PARENT)}}},[_vm._v(" Remove multi-part problem ")]):_vm._e(),(
            _vm.parentProblemSet?.permissions.includes(_vm.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.addNewPart}},[_vm._v(" Add new part ")]):_vm._e()]:_vm._e(),(
          _vm.parentProblemSet?.permissions.includes(_vm.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openRemoveDialog(_vm.RemoveDialogMode.MEMBER)}}},[_vm._v(" Remove "+_vm._s(_vm.memberLabel)+" ")]):_vm._e(),(_vm.member?.xref.startsWith('W'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Copy "+_vm._s(_vm.memberLabel)+" ")]),_c('v-list-item',_vm._g({staticClass:"disabled-menu-item"},on),[_vm._v(" Replace "+_vm._s(_vm.memberLabel)+" with Copy ")])]}}],null,false,1241233277)},[_c('span',[_vm._v(_vm._s(_vm.memberLabel)+" must be published to copy")])]):[(
            _vm.parentProblemSet?.permissions.includes(_vm.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('open-copy-pr-dialog', {
              xref: _vm.member?.xref,
              parent: _vm.parent,
            })}}},[_vm._v(" Copy "+_vm._s(_vm.memberLabel)+" ")]):_vm._e(),(
            _vm.parentProblemSet?.permissions.includes(_vm.AclPermissionType.UPDATE)
          )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('open-copy-pr-dialog', {
              xref: _vm.member?.xref,
              parent: _vm.parent,
              replace: true,
            })}}},[_vm._v(" Replace "+_vm._s(_vm.memberLabel)+" with Copy ")]):_vm._e()],(
          _vm.member?.permissions.includes(_vm.AclPermissionType.UPDATE) &&
          _vm.member?.contentType == _vm.ContentType.PROBLEM
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.editStandards}},[_vm._v(" Edit Standards ")]):_vm._e(),(_vm.member?.contentType == _vm.ContentType.PROBLEM)?_c('v-list-item',{staticClass:"icon-list-item d-flex justify-space-between align-center",attrs:{"link":""},on:{"click":function($event){return _vm.$emit('set-test-mode', {
            xref: _vm.member?.xref,
            testMode: !_vm.memberTestMode,
          })}}},[_c('span',[_vm._v(" Test Mode on "+_vm._s(_vm.memberLabel)+" ")]),(_vm.memberTestMode)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1):_vm._e()],2)],1),_c('RemoveFromProblemSetDialog',{attrs:{"data":_vm.membership},on:{"removed":function($event){return _vm.navigateToSibling($event)}},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }