var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","data-cy":"open-problem-menu-btn"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}]),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-list',[(
          _vm.isTeacherUser &&
          [_vm.PageView.LESSON_VIEW, _vm.PageView.SEARCH_RESULTS].includes(_vm.parentPage)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.saveToMyProblemSets = true}}},[_vm._v(" SAVE TO MY PROBLEM SETS ")]):_vm._e(),(_vm.isTeacherUser || _vm.isTrustedBuilderUser)?_c('v-list-item',{staticClass:"text-uppercase",attrs:{"link":"","href":_vm.previewLink,"target":"blank","data-cy":"preview-ps"},on:{"click":_vm.handlePreviewClick}},[_vm._v(" PREVIEW AS STUDENT "),_c('PreviewInformationPopup')],1):_vm._e(),(
          _vm.isTeacherUser &&
          (_vm.parentPage === _vm.PageView.EDIT_MY_PROBLEM_SET ||
            _vm.parentPage === _vm.PageView.LESSON_VIEW)
        )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.editStudentSupports = true}}},[_vm._v(" EDIT STUDENT SUPPORTS ")]):_vm._e(),(_vm.parentPage === _vm.PageView.SEARCH_RESULTS && !_vm.isPartProblem)?_c('v-list-item',{attrs:{"link":"","data-cy":"go-to-ps"},on:{"click":function($event){_vm.navigateToProblemSet = true}}},[_vm._v(" GO TO PROBLEM SET ")]):_vm._e(),(
          _vm.parentPage === _vm.PageView.EDIT_MY_PROBLEM_SET &&
          !_vm.isPartProblem &&
          _vm.parent?.permissions.includes(_vm.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":"","disabled":_vm.isFirst,"data-cy":"move-up"},on:{"click":function($event){return _vm.$emit('move-up')}}},[_vm._v(" MOVE UP ")]):_vm._e(),(
          _vm.parentPage === _vm.PageView.EDIT_MY_PROBLEM_SET &&
          !_vm.isPartProblem &&
          _vm.parent?.permissions.includes(_vm.AclPermissionType.UPDATE)
        )?_c('v-list-item',{attrs:{"link":"","data-cy":"move-down","disabled":_vm.isLast},on:{"click":function($event){return _vm.$emit('move-down')}}},[_vm._v(" MOVE DOWN ")]):_vm._e(),(
          _vm.parentPage === _vm.PageView.EDIT_MY_PROBLEM_SET &&
          !_vm.isPartProblem &&
          _vm.parent?.permissions.includes(_vm.AclPermissionType.UPDATE)
        )?[_c('v-divider'),_c('v-list-item',{attrs:{"link":"","data-cy":"remove-problem"},on:{"click":function($event){return _vm.$emit('remove')}}},[_vm._v(" REMOVE PROBLEM ")])]:_vm._e()],2)],1),_c('SaveToMyProblemSetsDialog',{attrs:{"selectedTree":_vm.selectedTree},model:{value:(_vm.saveToMyProblemSets),callback:function ($$v) {_vm.saveToMyProblemSets=$$v},expression:"saveToMyProblemSets"}}),_c('StudentSupportsDialog',{attrs:{"problem":_vm.problem},model:{value:(_vm.editStudentSupports),callback:function ($$v) {_vm.editStudentSupports=$$v},expression:"editStudentSupports"}}),_c('GoToParentProblemSetDialog',{attrs:{"ceri":_vm.problem.xref,"source":_vm.EXPLORE_CONTENT,"returnText":"Search Results"},model:{value:(_vm.navigateToProblemSet),callback:function ($$v) {_vm.navigateToProblemSet=$$v},expression:"navigateToProblemSet"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }