import { MutationTree } from 'vuex';
import { SearchState } from './types';
import { getDefaultState } from '.';

export const mutations: MutationTree<SearchState> = {
  resetProblemSearch(state): void {
    const search = getDefaultState();
    // I don't understand why but we are keeping ONLY skillBuilders?
    // Why did we reset its next page here then?
    search.skillBuilders = state.skillBuilders;
    Object.assign(state, search);
  },
  setSearchResults(state: SearchState, searchResults: string[]): void {
    state.searchResults = searchResults;
  },
  setProblemCount(state: SearchState, numProblems: number): void {
    state.problemCount = numProblems;
  },
  setProblemSetCount(state: SearchState, numProblems: number): void {
    state.problemSetCount = numProblems;
  },
  setSkillBuilders(state: SearchState, skillBuilders: string[]): void {
    state.skillBuilders = skillBuilders;
  },
  setIsDownloadingProblems(state: SearchState, value: boolean): void {
    state.isDownloadingProblems = value;
  },
  setNextPageToken(state: SearchState, value: string | null): void {
    state.nextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setSkillBuilderNextPageToken(state: SearchState, value: string | null): void {
    state.skillBuilderNextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setProblemSetsNextPageToken(state: SearchState, value: string | null): void {
    state.problemSetsNextPageToken = value;

    state.shouldDownloadMoreProblems = value ? true : false;
  },
  setShouldDownloadMoreProblems(state: SearchState, value: boolean): void {
    state.shouldDownloadMoreProblems = value;
  },
};
