
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PartLogData } from '@/domain/ReportData/AssignmentData';
import { QuickComment, QuickCommentFeedback } from '@/domain/QuickComment';
import QuickCommentsStatus from '../QuickCommentsStatus.vue';
import { QCStatus } from '@/views/MyAssignments/EssayScoringPage.vue';
import { User } from '@/domain/User';
import {
  ProblemForReport,
  getProblemTitleForReport,
} from '@/utils/report.util';

@Component({ components: { QuickCommentsStatus } })
export default class FeedbackSideSheet extends Vue {
  @Prop({ required: true }) value: boolean;
  @Prop({ required: true }) student: User;
  @Prop({ required: true }) problem: ProblemForReport;
  @Prop({ required: true }) partLogData: PartLogData;
  @Prop({ required: false }) quickComments: QuickComment | undefined;
  @Prop({ default: () => QCStatus.NONE }) quickCommentsStatus: QCStatus;
  @Prop({ required: false, default: false }) updating: boolean;

  availableScores: Array<number> = [0, 1, 2, 3, 4];

  isExpanded = false;

  get continuousScore(): number | undefined {
    return typeof this.partLogData.continuousScore === 'number'
      ? this.partLogData.continuousScore * 4
      : undefined;
  }

  get teacherComment(): string {
    return this.partLogData.teacherComment;
  }

  // FIXME: Will the response list always be of size 1?
  get answerText(): string {
    return this.partLogData.response?.[0] ?? '';
  }

  get title(): string {
    return getProblemTitleForReport(this.problem, false);
  }

  get showDrawer(): boolean {
    return this.value;
  }

  set showDrawer(val: boolean) {
    this.$emit('input', val);
  }

  get drawerOption(): string {
    return this.isExpanded ? '>> Collapse' : '<< Expand';
  }

  get quickCommentFeedbacks(): QuickCommentFeedback[] {
    let feedbacks: QuickCommentFeedback[] = [];
    if (this.quickComments) {
      let qcIndex: number;
      let suggestedScore;
      if (this.quickComments.scoreIsAccurate) {
        suggestedScore = this.quickComments?.suggestedScore;
      }
      if (typeof this.continuousScore === 'number') {
        qcIndex = this.continuousScore;
      } else {
        qcIndex = suggestedScore as number;
      }
      if (this.quickComments.commentIsAccurate) {
        feedbacks = this.quickComments.comments.filter(
          (obj) => obj.score == qcIndex
        );
        // If there are no QC for the suggested score, assume the new version is
        // active and show all.
        if (feedbacks.length === 0) {
          feedbacks = this.quickComments.comments;
        }
      }
    }
    return feedbacks;
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  updateScore(value: number): void {
    this.$emit('update-part-log', { continuousScore: value / 4 });
  }

  updateComment(value: string): void {
    this.$emit('update-part-log', { teacherComment: value });
  }

  mounted(): void {
    window.com.wiris.js.JsPluginViewer.parseDocument();
  }

  @Watch('answerText')
  onAnswerText(): void {
    this.$nextTick(() => {
      window.com.wiris.js.JsPluginViewer.parseDocument();
    });
  }
}
