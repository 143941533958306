
import SearchByCeriSelector from '@/components/Builder/SearchByCeriSelector.vue';
import FolderFileView from '@/components/Builder/FolderFileView.vue';
import { Component, Vue } from 'vue-property-decorator';
import { EXPLORE_CONTENT, FolderDefinition, WORKSPACE } from '@/domain/Folder';
import { BuilderMode } from '@/components/Builder/BuilderToolbar.vue';
import { Mode } from '@/components/Builder/FolderExplorer.vue';
import FolderFileMenu from '@/components/Builder/FolderFileMenu.vue';
import { ContentType } from '@/domain/Content';

@Component({
  components: {
    FolderFileMenu,
    FolderFileView,
    SearchByCeriSelector,
  },
})
export default class MainHierarchyPage extends Vue {
  isDownloading = false;
  mode: BuilderMode.READ | BuilderMode.EDIT = BuilderMode.READ;

  BuilderMode = BuilderMode;
  Mode = Mode;
  ContentType = ContentType;
  EXPLORE_CONTENT = EXPLORE_CONTENT;
  WORKSPACE = WORKSPACE;

  get xrefs(): string[] {
    const xrefs = [];
    if (this.isContentAdminUser) {
      xrefs.push(EXPLORE_CONTENT);
    }
    xrefs.push(WORKSPACE);
    return xrefs;
  }

  get folderMap(): Record<string, FolderDefinition> {
    return this.$store.state.folder.folderMap;
  }

  // Show only folders that were downloaded successfully.
  get folders(): FolderDefinition[] {
    const downloaded = [];
    for (const xref of this.xrefs) {
      const folder = this.folderMap[xref];
      if (folder) {
        downloaded.push(folder);
      }
    }
    return downloaded;
  }

  goToProblemSetBuilder(xref: string): void {
    // Go to the Builder.
    this.$router.push({
      name: 'contentBuilder',
      params: {
        psXref: xref,
      },
      query: { ...this.$route.query },
    });
  }

  created(): void {
    this.isDownloading = true;
    // Initialize store with Explore Content and Workspace Folders.
    this.$store.dispatch('folder/getFolders', this.xrefs).finally(() => {
      this.isDownloading = false;
    });
    // Download curricula if not already.
    this.$store.dispatch('curricula/requestCurricula');
  }

  goToProblemSet(path: string): void {
    let pathParts = path.split(',');
    const targetIndex = pathParts.findIndex(
      (part) => part.startsWith('WPS') || part.startsWith('PS')
    );
    // FIXME: Figure out if we need to handle navigating to a Folder.
    if (targetIndex !== -1) {
      const target = pathParts[targetIndex];
      pathParts = pathParts.slice(targetIndex);
      let contentPath = undefined;
      if (pathParts.length > 1) {
        contentPath = pathParts.join(',');
      }
      this.$router.push({
        name: 'contentBuilder',
        params: {
          psXref: target,
        },
        query: {
          ...this.$route.query,
          contentPath,
          mode: BuilderMode.EDIT,
        },
      });
    }
  }
}
