
import { getFolderPathsTo } from '@/api/core/folders.api';
import { FolderDefinition } from '@/domain/Folder';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SearchByCeriSelector extends Vue {
  @Prop({ required: true }) sources: string[];

  ceri: string | null = null;
  // Folder Paths returned exclude member as a path element/part (do not end with member).
  paths: (FolderDefinition | ProblemSetDefinition)[][] = [];
  hideNoData = true;

  isDownloading = false;

  get pathSelections(): { text: string; value: string[] }[] {
    return this.paths.map((path) => ({
      text: path.map((element) => element.name).join(' / '),
      value: path.map((element) => element.xref),
    }));
  }

  findPaths() {
    if (!this.sources.length) {
      // Show if no results
      this.hideNoData = false;
    } else if (this.ceri) {
      this.isDownloading = true;
      // FIXME: Figure out if target file would be included in requested path.
      getFolderPathsTo(this.sources, this.ceri)
        .then((paths) => {
          this.paths = paths;
        })
        .finally(() => {
          this.isDownloading = false;
          // Show if no results
          this.hideNoData = false;
        });
    }
  }

  selectPath(path: string[]) {
    if (this.ceri) {
      this.$emit('search', [...path, this.ceri].join(','));
    }
  }

  @Watch('ceri')
  onCeri(): void {
    if (this.ceri) {
      this.ceri = this.ceri.toUpperCase();
    }
    // Search is not performed. No need to show no matches.
    this.hideNoData = true;
    this.paths = [];
  }
}
