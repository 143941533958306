
import { Component, Vue, Watch } from 'vue-property-decorator';
import { logOut } from '@/api/auth.api';
import {
  getUserMigrationStatus,
  getMigrationCompletionStatus,
  MigrationStatusRaw,
} from '@/api/core/migration.api';

import { RawLocation } from 'vue-router';
import LoginButton from '@/components/base/LoginButton.vue';
import ImportSyncDialog from '@/components/FindProblems/ImportSyncDialog.vue';
import PopupBlockedDialog from '@/components/base/PopupBlockedDialog.vue';
import { ComposedError } from '@/plugins/axios';
import { EXPLORE_CONTENT, WORKSPACE } from '@/domain/Folder';
import { EventType, trackMixpanel } from '@/plugins/mixpanel';
import MigrationDialog from './MigrationDialog.vue';

interface NavigationItem {
  title: string;
  icon?: string;
  link?: RawLocation;
  cypressAttribute?: string;
  subLinks?: Array<NavigationItem>;
  onClick?(): unknown;
}

export enum MigrationStatus {
  UNKNOWN = 'UNKNOWN',
  COMPLETE = 'COMPLETE',
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
}

@Component({
  components: {
    PopupBlockedDialog,
    ImportSyncDialog,
    LoginButton,
    MigrationDialog,
  },
})
export default class NavBar extends Vue {
  isDrawerCollapsed = false;
  importSyncDialog = false;
  menuVisible = false;

  // Migration Variables
  MigrationStatus = MigrationStatus;

  migrationStatistics: MigrationStatusRaw | null = null;
  migrationStatus = MigrationStatus.UNKNOWN;
  migrationId = '';
  migrationDialog = false;
  migrationPercentage = 0;

  alert = {
    show: false,
    type: 'success',
    msg: '',
  };

  popupBlockedDialog = false;
  assignError: ComposedError | null = null;

  get navItems(): Array<NavigationItem> {
    const items: Array<NavigationItem> = [
      {
        title: 'Find & Assign',
        link: { name: 'findProblems' },
        icon: 'mdi-magnify',
        cypressAttribute: 'find-problems-tab',
      },
    ];
    items.push({
      title: 'My Assignments',
      link: { name: 'myAssignments' },
      icon: 'mdi-clipboard-text',
      cypressAttribute: 'my-assignments-tab',
    });
    items.push({
      title: 'My Problem Sets',
      link: { name: 'myProblemSets' },
      icon: 'mdi-folder-heart',
      cypressAttribute: 'my-problem-sets-tab',
    });
    if (this.tutorReportAccess) {
      items.push({
        title: 'Tutor Report',
        link: { name: 'tutorReport' },
        icon: 'mdi-account-group',
        cypressAttribute: 'tutor-report-tab',
      });
    }
    if (this.insightsHubAccess) {
      items.push({
        title: 'Insights Hub',
        link: { name: 'insightsHubLanding' },
        icon: 'mdi-chart-bar',
        cypressAttribute: 'insights-hub-tab',
      });
    }
    if (this.isContentAdminUser || this.isTrustedBuilderUser) {
      const subLinks = [];
      if (this.isContentAdminUser) {
        subLinks.push({
          title: 'Explore Content',
          link: { name: 'mainHierarchyPage', query: { p: EXPLORE_CONTENT } },
        });
      }
      subLinks.push({
        title: 'Workspace',
        link: { name: 'mainHierarchyPage', query: { p: WORKSPACE } },
      });
      items.push({
        title: 'Builder',
        link: { name: 'mainHierarchyPage' },
        icon: 'mdi-hammer-wrench',
        cypressAttribute: 'builder-tab',
        subLinks,
      });
    }
    return items;
  }

  get menuItems(): Array<NavigationItem> {
    const items: Array<NavigationItem> = [
      // MUST allow sync to contact LMS for verified TEACHER role for the first time?
      {
        title: 'Import / Sync ',
        icon: 'mdi-autorenew',
        onClick: this.showSyncDialog,
      },
      // FIXME: Figure out if TEACHER only.
      {
        title: 'Settings',
        icon: 'mdi-cog',
        onClick: this.navigateToSettings,
        cypressAttribute: 'account-menu-settings-btn',
      },
    ];

    if (this.isTeacherUser) {
      items.push({
        title: 'Import 1.0 Content',
        icon: 'mdi-shield-link-variant',
        onClick: this.shownMigrationDialog,
        cypressAttribute: 'import-content-btn',
      });
    }

    items.push({
      title: 'Log Out',
      icon: 'mdi-logout',
      onClick: () => {
        trackMixpanel(EventType.trackLogOut);
        logOut();
      },
      cypressAttribute: 'account-menu-logout-btn',
    });

    return items;
  }

  get alertColor(): string {
    switch (this.alert.type) {
      case 'error':
        return 'red';
      case 'success':
        return 'green';
      case 'warning':
        return 'orange';
      default:
        return '';
    }
  }

  //Consider a better way for handling this so that the restricted paths don't grow forever (maybe the router)
  get hideNavDrawer(): boolean {
    const restrictedPaths: string[] = ['settings', 'registration', 'student'];

    return restrictedPaths.some((path) => this.$route.path.includes(path));
  }

  get navbarTitle(): string {
    return `${
      process.env.VUE_APP_APPEND_TITLE
        ? `${process.env.VUE_APP_APPEND_TITLE} ${process.env.VUE_APP_VERSION}`
        : ''
    }`;
  }
  get displayName(): string {
    return this.$store.state.auth.user.displayName;
  }
  get userName(): string {
    return this.$store.state.auth.user.userName;
  }

  get tutorReportAccess(): boolean {
    return this.$store.state.auth.user?.settings.accessToLDOEReport || false;
  }

  get insightsHubAccess(): boolean {
    return this.$store.state.auth.user?.settings.accessToInsightsHub || false;
  }

  get collapseMenuButtonIcon(): string {
    const arrowIconDirection = this.isDrawerCollapsed ? 'right' : 'left';

    return `mdi-arrow-${arrowIconDirection}`;
  }

  showAlert(type: string, msg: string): void {
    this.alert = {
      show: true,
      type: type,
      msg: msg,
    };
  }

  showSyncDialog(): void {
    this.importSyncDialog = true;
  }

  shownMigrationDialog(): void {
    this.migrationDialog = true;
  }

  popupBlocked(error: any): void {
    this.assignError = error;
    this.popupBlockedDialog = true;
  }

  collapseDrawer(): void {
    this.isDrawerCollapsed = !this.isDrawerCollapsed;
  }

  navigateToSettings(): void {
    this.$router.push({
      name: 'accountSettings',
    });
  }

  navigateToHomepage(): void {
    this.$router.push({
      name: 'findProblems',
    });
  }

  updateMigrationStatus(newStatus: MigrationStatus): void {
    this.migrationStatus = newStatus;
  }

  updateMigrationId(id: string): void {
    this.migrationId = id;
  }

  updateMigrationStatistics(): void {
    getMigrationCompletionStatus(this.migrationId).then(
      (compStatus: MigrationStatusRaw) => {
        this.migrationStatistics = compStatus;
      }
    );
  }

  @Watch('menuVisible')
  checkMigrationStatus(menuOpen: boolean): void {
    if (menuOpen) {
      // Unknown = First load, get the status to see if migration is complete
      // In Progress = Migration started, update current status
      // Complete || Not started = do nothing on menu open
      if (this.migrationStatus == MigrationStatus.IN_PROGRESS) {
        this.updateMigrationStatistics();
      } else if (this.migrationStatus == MigrationStatus.UNKNOWN) {
        getUserMigrationStatus()
          .then((uuid) => {
            this.migrationId = uuid;
            this.updateMigrationStatistics();
          })
          .catch((error) => {
            // 404 is ok and means no migration found/not started
            if (error.statusCode === 404) {
              this.updateMigrationStatus(MigrationStatus.NOT_STARTED);
            } else {
              error.handleGlobally();
            }
          });
      }
    }
  }

  @Watch('migrationStatistics')
  updateMigrationPercentage() {
    if (this.migrationStatistics) {
      const percent =
        ((this.migrationStatistics.psaPublished +
          this.migrationStatistics.psaFailed) /
          this.migrationStatistics.totalPsaToProcess) *
        100;

      this.migrationStatus =
        percent === 100
          ? MigrationStatus.COMPLETE
          : MigrationStatus.IN_PROGRESS;
      this.migrationPercentage = percent;
    } else {
      this.migrationPercentage = 0;
    }
  }
}
