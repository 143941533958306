
declare let tinyMCE: EditorManager;
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProblemDefinition } from '@/domain/Problem';
import Editor from '@tinymce/tinymce-vue';
import editorConfig from '@/utils/tinyMCE/studentSupportAuthoringConfig.util';
import { uniqueId } from 'lodash';
import {
  UploadResult,
  EditorManager,
} from '@/utils/tinyMCE/tinymceInterfaces.util';

@Component({
  components: {
    Editor,
  },
})
export default class SupportEditor extends Vue {
  @Prop() value: boolean; // Whether the dialog is open or not
  @Prop() problem: ProblemDefinition;
  @Prop({ default: '' }) supportContent: string;
  @Prop() title: string;
  @Prop({ default: true }) showTopDeleteButton: boolean;

  showEditor = false;
  editorData = editorConfig;
  editorId = uniqueId('tinymce');

  supportTypeSelected = null;
  selectedTimeFrame: string;

  content = '';

  created() {
    this.content = this.supportContent;
  }

  publish() {
    this.uploadImages(
      () => {
        this.$emit('publish', this.content);
        this.cancelEditor();
      },
      () => {
        throw 'uh oh';
      }
    );
  }

  cancelEditor() {
    this.$emit('cancelEditor');
  }

  deleteThis() {
    this.$emit('remove');
    this.cancelEditor();
  }

  //TODO: can I change this to a promise?
  uploadImages(onSuccess: () => void, onFailure: () => void) {
    const tmce = tinyMCE;
    tinyMCE.get(this.editorId).plugins.assistcompress.compressAndUpload(
      (
        //TODO: Fix this. We shouldn't need our own "custom" UploadResult.
        // The compressAndUpload command should probably have some way of handling
        // typescript stuff or potentially changed to not be a plugin anymore?
        success: UploadResult[]
      ) => {
        let proceed = true;
        for (let i = 0; i < success.length; i++) {
          const obj = success[i];
          if (obj.status) {
            tmce.$(obj.element).attr('data-assist-backup-src', null);
          } else {
            proceed = false;
            const imgElm = tmce.$(obj.element);
            imgElm.attr('src', imgElm.attr('data-assist-backup-src'));
          }
        }
        this.content = tinyMCE.get(this.editorId).getContent();
        if (proceed) {
          onSuccess();
        } else {
          onFailure();
        }
      }
    );
  }
}
