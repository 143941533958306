
import { Component, Vue } from 'vue-property-decorator';
import { ProblemSetDefinition } from '@/domain/ProblemSet';
import SkillBuilderInfoDialog from '@/components/FindProblems/SkillBuilderInfoDialog.vue';

@Component({ components: { SkillBuilderInfoDialog } })
export default class SearchResultsSkillBuilders extends Vue {
  showSkillBuilderInfo = false;

  get skillBuilders(): ProblemSetDefinition[] {
    return this.$store.getters['search/getSkillBuilders'];
  }

  get selectedSkillXref(): string {
    return this.$store.state.skillList.selectedSkillXref;
  }

  goToSkillBuilder(ps: ProblemSetDefinition): void {
    this.$router.push({
      name: 'LessonPageProblemSet',
      params: {
        type: 'ps',
        problemSetXref: `${ps.xref}`,
      },
      query: {
        returnToName: 'SEARCH RESULTS',
        returnToPath: this.$router.currentRoute.fullPath,
      },
    });
  }
}
