
import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchResultsTopBar from '@/components/FindProblems/SearchResults/SearchResultsTopBar.vue';
import { SelectedFilter } from './SearchResultsProblems.vue';
import {
  ProblemSetsFilterParams,
  ProblemsFilterParams,
} from '@/api/core/content.api';
import {
  DefinitionInclude,
  DefinitionParams,
  PagingParams,
} from '@/api/core/base.api';
import { ProblemSetType } from '@/domain/ProblemSet';

@Component({
  components: {
    SearchResultsTopBar,
  },
})
export default class SearchResults extends Vue {
  downloading = false;

  // Filtering/paging
  limit = 20;
  controller: AbortController | undefined = undefined;
  extraFields = [
    'ANSWERS',
    'PARENT_PROBLEM_SETS',
    'CURRICULA',
    'ATTRIBUTIONS',
    'SKILLS',
  ];

  selectedFilter: SelectedFilter = { curricula: [], problemType: [] };

  get nextPageToken(): string | null {
    return this.$store.state.search.nextPageToken;
  }

  get skillBuilderNextPageToken(): string | null {
    return this.$store.state.search.skillBuilderNextPageToken;
  }

  get filterParams(): ProblemsFilterParams {
    const params: ProblemsFilterParams = {
      skills: [this.selectedSkillXref],
      isTextbook: false,
      isResearch: false,
      isCertified: true,
      curricula: this.selectedFilter.curricula,
      problemSetTypes: [
        ProblemSetType.LINEAR_COMPLETE_ALL,
        ProblemSetType.RANDOM_COMPLETE_ALL,
        ProblemSetType.STUDENT_CHOICE,
        ProblemSetType.IF_THEN_ELSE,
        ProblemSetType.RANDOM_COMPLETE_ONE,
      ],
      types: this.selectedFilter.problemType,
    };
    return params;
  }

  get skillBuilderFilterParams(): ProblemSetsFilterParams {
    const params: ProblemSetsFilterParams = {
      skills: [this.selectedSkillXref],
      isResearch: false,
      isCertified: true,
      types: [
        ProblemSetType.SKILL_BUILDER_RANDOM,
        ProblemSetType.SKILL_BUILDER_LINEAR,
      ],
    };
    return params;
  }

  get pagingParams(): PagingParams {
    return { limit: this.limit, nextPageToken: this.nextPageToken };
  }

  get skillBuilderPagingParams(): PagingParams {
    return { limit: this.limit, nextPageToken: this.skillBuilderNextPageToken };
  }

  get definitionParams(): DefinitionParams {
    return { include: [DefinitionInclude.ATTRIBUTES] };
  }

  get selectedSkillXref(): string {
    const skillXref = this.$store.state.skillList.selectedSkillXref;
    return skillXref;
  }

  resetSearch(): void {
    // Cancel previous request if any...
    if (this.controller) {
      this.controller.abort();
    }

    // Clear out prior results.
    this.$store.commit('search/resetProblemSearch');
  }

  created(): void {
    const skillXref = this.$route.params.skillXref;
    // Redirect home if missing route param
    if (!skillXref) {
      this.$router.push({
        name: 'findProblems',
      });
      return;
    }

    this.downloading = true;

    this.$store.commit('skillList/setSelectedSkill', skillXref);

    // If list of skills is missing from store, request it
    if (this.$store.state.skillList.skills.length === 0) {
      this.$store.dispatch('skillList/requestSkills');
    }

    // Download all curricula if not already
    this.$store.dispatch('curricula/requestCurricula');

    this.downloadProblems().finally(() => {
      this.downloading = false;
    });
  }

  @Watch('$route.params.skillXref')
  onSkillXrefChange(value: string, oldValue: string): void {
    if (value === oldValue) {
      // Nothing more to do.
      return;
    }
    // Clear prior search results.
    this.resetSearch();
    this.downloadProblems();
  }

  requestProblemsBySkill(controller: AbortController) {
    return this.$store.dispatch('search/searchProblemsBySkill', {
      filterParams: this.filterParams,
      pagingParams: this.pagingParams,
      definitionParams: this.definitionParams,
      abortController: controller,
    });
  }

  requestSkillBuilders(controller: AbortController) {
    return this.$store.dispatch('search/requestSkillBuildersBySkill', {
      filterParams: this.skillBuilderFilterParams,
      pagingParams: this.skillBuilderPagingParams,
      definitionParams: this.definitionParams,
      abortController: controller,
    });
  }

  downloadProblems(): Promise<void> {
    // Cancel previous request if any...
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();
    const problemPromise = this.requestProblemsBySkill(this.controller);
    const skillBuilderPromise = this.requestSkillBuilders(this.controller);
    // Load page once the relevant request finishes...
    const promise =
      this.$router.currentRoute.name === 'searchProblems'
        ? problemPromise
        : skillBuilderPromise;
    return promise;
  }

  onSelectedFilterChanged(selectedFilter: SelectedFilter) {
    this.selectedFilter = selectedFilter;
    this.resetSearch();
    this.downloadProblems();
  }
}
